import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ITemplate} from "../index";
import {AdminTemplateService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {Form} from "react-bootstrap";
import CreateForm from "./CreateForm";

const UpdateTemplate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState<ITemplate | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const submitHandler = () => {
        const formData = new FormData();
        formData.append("body", JSON.stringify(state));
        image && formData.append("image", image);
        !!state?.id && AdminTemplateService.update(formData, state?.id)
            .then(() => {
                SuccessToast(t(`template.successfully_updated`));
                navigate(-1);
                setState(null);
            })
            .catch((error) => ErrorToast(error));
    };
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState} setImage={setImage}/>
            </Form>
        </div>
    );
};

export default UpdateTemplate;