import {requests} from "../../helpers/api.services";
import {TEMPLATE_URL} from "../../helpers/api.routes";
import {IPagination} from "../../shared/interface";

export const AdminTemplateService = {
    getAll: (params: IPagination) => requests.get(`${TEMPLATE_URL}`, params),
    create: (payload: FormData) => requests.post(`${TEMPLATE_URL}`, payload, true),
    getSingle: (id: number) => requests.get(`${TEMPLATE_URL}/${id}`),
    update: (payload: FormData, id: number) => requests.post(`${TEMPLATE_URL}/${id}`, payload, true),
    delete: (id: number) => requests.delete(`${TEMPLATE_URL}/${id}`),
    updatePosition: (ids: number[]) => requests.patch(`${TEMPLATE_URL}`, {ids}),
}