import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {AdminTemplateSettingsService} from "./service";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";

export interface ITemplateSettings {
    title?: string,
    subTitle?: string
}

const AdminTemplateSettings = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<ITemplateSettings>({ title: "", subTitle: "" });

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const submitHandler = () => {
        if (state) {
            AdminTemplateSettingsService.create(state)
                .then((response) => {
                    SuccessToast(t(`template.successfully_updated_settings`));
                    setState(response.data);
                })
                .catch((error) => ErrorToast(error));
        }
    };

    useEffect(() => {
        AdminTemplateSettingsService.get()
            .then((response) => {
                const { data } = response;
                setState(data);
            })
            .catch((error) => ErrorToast(error));
    }, []);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6} className="mt-2">
                            <label>{t("global.title")}</label>
                            <input
                                name="title"
                                type="text"
                                className="form-control"
                                onChange={changeHandler}
                                value={state?.title}
                            />
                        </Col>
                        <Col md={6} className="mt-2">
                            <label>{t("global.subtitle")}</label>
                            <input
                                name="subTitle"
                                type="text"
                                className="form-control"
                                onChange={changeHandler}
                                value={state?.subTitle}
                            />
                        </Col>
                    </Row>
                    <div className="col-12 mt-5 d-flex justify-content-end">
                        <button className="btn btn-primary ms-3" type={'submit'}>
                            {t("global.save")}
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </Form>
    );
};

export default AdminTemplateSettings;
