import React, {ChangeEvent, SetStateAction, useEffect} from 'react';
import {ITemplate} from "../index";
import {useTranslation} from "react-i18next";
import {Card, Col, Row} from "react-bootstrap";
import {AdminTemplateService} from "../service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {InitEditor} from "../../../shared/tinyMce/InitEditor";
import {useNavigate, useParams} from "react-router-dom";
import {PICTURE_URL} from "../../../helpers/api.routes";

interface IProps {
    state: ITemplate | null;
    setState: React.Dispatch<SetStateAction<ITemplate | null>>;
    setImage: React.Dispatch<SetStateAction<File | null>>;
}

const CreateForm = ({state, setState, setImage}: IProps) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    function handleChange(event: ChangeEvent<HTMLInputElement>, isNumber = false) {
        const {name, value, valueAsNumber} = event.target;
        setState((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}) as ITemplate);
    }

    const handleText = (description: string) => {
        setState((prev) => ({...prev, description}) as ITemplate);
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as ITemplate);
    };
    const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            console.log(file);
            setImage(file);
        }
    };

    useEffect(() => {
        if (id) {
            AdminTemplateService.getSingle(+id)
                .then(response => {
                    const {data} = response;
                    InitEditor(data?.description, handleText);
                    setState(response?.data)
                }).catch(err => ErrorToast(err))
        } else {
            InitEditor(undefined, handleText);
        }
    }, [id])
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <label htmlFor="title">
                            {t("global.title")}
                        </label>
                        <input type="text"
                               name={'title'}
                               value={state?.title}
                               onChange={handleChange}
                               className={'form-control'}
                        />
                    </Col>
                    <Col md={6}>
                        <div className="form-check form-switch p-0">
                            <label htmlFor="isActive">
                                {t("global.active")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={"isActive"}
                                    checked={state?.isActive}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="isActive"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="my-3">
                        <label htmlFor="name">
                            {t("global.price")}
                        </label>
                        <input type="number"
                               name={'price'}
                               value={state?.price}
                               onChange={(e) => handleChange(e, true)}
                               className={'form-control'}
                        />
                    </Col>
                    <Col md={6} className="my-3">
                        <label htmlFor="pricePdv">
                            {t("global.pricePdv")}
                        </label>
                        <input type="text"
                               name={'pricePdv'}
                               value={state?.pricePdv}
                               onChange={handleChange}
                               className={'form-control'}
                        />
                    </Col>
                    <Col md={12}>
                        <label htmlFor="description">
                            {t("global.description")}
                        </label>
                        <textarea className="editor" onChange={console.log}></textarea>

                        <label htmlFor="imageFile" className={'mt-3'}>
                            {t("download.image")}
                        </label>
                        <input
                            onChange={fileHandler}
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="imageFile"
                        />
                        {state?.image ? (
                            <img
                                className="mt-3 rounded show-img-form"
                                alt="Preview"
                                src={
                                    state.image instanceof File
                                        ? URL.createObjectURL(state.image)
                                        : `${PICTURE_URL}${state.image.path}`
                                }
                                data-holder-rendered="true"
                            />
                        ) : (
                            <p>No image available</p>
                        )}

                    </Col>
                    <Col md={12} className={'d-flex align-items-end justify-content-end mt-3'}>
                        <button
                            className="btn btn-info me-3"
                            type={"button"}
                            onClick={()=> navigate("/admin/template")}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary" type="submit">
                            {t(state?.id ? "global.update" : "global.create")}
                        </button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;